import React, { useCallback, useState } from "react";
import {
  CONFIRM_CONTEXT_DELETE,
  CONTEXT_DELETE,
  createMessage,
} from "ee/constants/messages";
import { MenuItem } from "@appsmith/ads";
import { deleteModule } from "ee/actions/moduleActions";
import { deleteAction } from "actions/pluginActionActions";
import { moduleEditorURL } from "ee/RouteBuilder";
import { useDispatch } from "react-redux";
import { usePluginActionContext } from "PluginActionEditor";
import history from "utils/history";
import type { Action } from "entities/Action";

const DeleteModuleMenuItem = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const { action } = usePluginActionContext();

  const onDeleteModule = useCallback(
    (moduleId: string) => {
      dispatch(deleteModule({ id: moduleId }));
    },
    [dispatch],
  );

  const deleteActionFromModule = useCallback(
    (action: Action) => {
      dispatch(
        deleteAction({
          id: action.id,
          name: action.name,
          onSuccess: () =>
            history.push(moduleEditorURL({ moduleId: action.moduleId })),
        }),
      );
    },
    [dispatch],
  );

  const onDeleteClick = useCallback(() => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }

    if (action.isPublic && action.moduleId) {
      onDeleteModule(action.moduleId);
    } else {
      deleteActionFromModule(action);
    }
  }, [action, confirmDelete, deleteActionFromModule, onDeleteModule]);

  return (
    <MenuItem
      className="t--apiFormDeleteBtn error-menuitem"
      onSelect={onDeleteClick}
      startIcon="trash"
    >
      {confirmDelete
        ? createMessage(CONFIRM_CONTEXT_DELETE)
        : createMessage(CONTEXT_DELETE)}
    </MenuItem>
  );
};

export default DeleteModuleMenuItem;
